<template>
  <section
    class="invoice-preview-wrapper"
    style="position: relative"
  >
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="order === undefined"
    >
      <h4 class="alert-heading">
        Error fetching invoice data
      </h4>
      <div class="alert-body">
        No invoice found with this invoice id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'facture-list' }"
        >
          invoice List
        </b-link>
        for other invoice.
      </div>
    </b-alert>

    <b-row
      v-if="order"
      class="invoice-preview"
    >
      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-card
          no-body
          class="invoice-preview-card"
        >
          <!-- Header -->
          <b-card-body>
            <b-row
              class="invoice-padding"
              style="
                border: 1px solid black;
                border-radius: 18px;
                padding: 10px;
              "
            >
              <!-- Header: Left Content -->

              <b-col
                cols="12"
                md="12"
                style="color: black; font-size: 18px; text-align: center"
              >
                <img
                  src="@/assets/images/logo/logo.png"
                  width="150px"
                  height="auto"
                >
                <div
                  style="color: black; font-size: 28px"
                  class="mt-1 font-weight-bolder"
                >
                  GROUPES SERVICES RAPIDES <br>
                  <span style="font-size: 24px">
                    VENTE TOUT GENRE DE CLEFS BRUT, MACHINES ET
                    ACCESSOIRES</span>
                </div>

                <b-card-text
                  style="font-size: 16px"
                  class="font-weight-bold mt-1"
                >
                  <strong> RUE EL KOUNAITRA 1-dar Chaabene El Fehri</strong><br>
                  <strong>Tel:+216 97 607 740</strong>
                  <br>
                  <strong>Email:gsr-cle1@hotmail.fr</strong>
                  <br>
                  <strong>MF : 1082793 F/A/M/000 | C.D 837979P</strong>
                </b-card-text>
              </b-col>

              <!-- Header: Right Content -->
              <!-- Header: Right Content -->
            </b-row>
          </b-card-body>
          <b-card-body>
            <b-row style="color: black">
              <b-col
                md="8"
                sm="12"
              >
                <h3
                  class="font-weight-bolder"
                  style="
                    color: black;
                    font-size: 25px;
                    border: 1px solid black;
                    border-radius: 18px;
                    padding: 10px;
                    text-align: center;
                  "
                >
                  * BON DE TRANSFERT *
                </h3>
                <div class="mt-1 d-flex">
                  <table style="border: 1px solid black">
                    <tr
                      style="
                        color: black;
                        font-size: 16px;
                        border: 1px solid black;
                      "
                    >
                      <th style="text-align: center">
                        N° du pièce
                      </th>
                    </tr>
                    <tr>
                      <td
                        v-if="order"
                        style="text-align: center"
                      >
                        {{ order.id }}
                      </td>
                    </tr>
                  </table>
                  <table style="border: 1px solid black; margin-left: 10px">
                    <tr
                      style="
                        color: black;
                        font-size: 16px;
                        border: 1px solid black;
                      "
                    >
                      <th style="text-align: center">
                        Date
                      </th>
                    </tr>
                    <tr>
                      <td
                        v-if="order"
                        style="text-align: center"
                      >
                        {{ format(order.date) }}
                      </td>
                    </tr>
                  </table>
                </div>
              </b-col>
              <b-col
                v-if="order.customer"
                md="4"
                sm="12"
                style="
                  border: 1px solid black;
                  border-radius: 18px;
                  font-size: 14px;
                  padding: 10px;
                "
              >
                <!-- Header: Left Content -->
                <div style="color: black; font-size: 18px">
                  <h4
                    style="color: black; font-size: 20px"
                    class="mb-1 font-weight-bolder"
                  >
                    Client : {{ order.customer.company_name }}
                  </h4>
                  <span class="mb-0 mt-1 font-weight-bold">
                    Adresse : {{ order.customer.address }}
                  </span>
                  <b-card-text class="mb-0 mt-1 font-weight-bold">
                    MF : {{ order.customer.tax_identification_number }}
                  </b-card-text>
                </div>

                <!-- Header: Right Content -->
                <!-- Header: Right Content -->
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Invoice Description: Table -->
          <b-card-body>
            <b-table-lite
              :bordered="true"
              responsive
              :items="p"
              :fields="[
                'N°',
                'Réf',
                'Désignation',
                'Qté',
                'Prix U.H.T',
                'Total H.T',
                'Taux TVA',
                'Prix U.TTC',
                'Total TTC',
              ]"
              class="ta"
            />
          </b-card-body>
          <!-- Invoice Description: Total -->

          <b-row class="card-body">
            <b-col
              cols="4"
              md="5"
              xl="5"
              sm="12"
              style="
                border: 1px solid black;
                border-radius: 18px;
                padding: 10px;
              "
            >
              <span
                class="font-weight-bolder mt-1 h4"
                style="color: black; border-bottom: 1px solid black"
              >
                Arreteé le présent bon de transfert a la somme de:
              </span>
              <br><br>
              <span
                class="font-weight-bold mt-1 h4"
                style="color: black"
              >
                {{ lettre }}
              </span>
              <!-- <div v-html="order.note" /> -->
            </b-col>

            <b-col
              cols="4"
              md="3"
              xl="3"
              sm="12"
            >
              <!-- <table
                v-if="order"
                style="padding: 5px;"
              >
                <tr style="color:black;font-size:16px">
                  <th>Base</th>
                  <th>Taux</th>
                  <th>Montant</th>
                </tr>
                <tr>

                  <td v-if="order">
                    {{ order.price_ht }}
                  </td>
                  <td v-else>
                    0
                  </td>
                  <td>19,0</td>
                  <td v-if="order">
                    {{ order.tax }}
                  </td>
                  <td v-else>
                    0
                  </td>
                </tr>
              </table> -->
            </b-col>

            <b-col
              cols="4"
              md="4"
              xl="4"
              sm="12"
              class="p-0"
              style="border: 1px solid black; border-radius: 18px"
            >
              <table>
                <tbody>
                  <tr class="trborder">
                    <th scope="row">
                      Total H.Tva :
                    </th>
                    <td>
                      {{ order.price_ht }}
                    </td>
                  </tr>
                  <tr class="trborder">
                    <th scope="row">
                      Total Tva :
                    </th>
                    <td>{{ order.tax }}</td>
                  </tr>

                  <tr>
                    <th scope="row">
                      Total TTC :
                    </th>
                    <td>{{ order.price_ttc }}</td>
                  </tr>
                </tbody>
              </table>
            </b-col>
          </b-row>
          <b-row class="card-body text-center">
            <b-col
              cols="6"
              md="6"
              xl="6"
              sm="12"
            >
              <h5 style="color: black; font-size: 16px">
                Signature
              </h5>
            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions"
      >
        <b-card>
          <!-- Button: Print -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
            @click="printInvoice"
          >
            Print
          </b-button>

          <!-- Button: Edit -->
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { ref } from '@vue/composition-api'
import axiosIns from '@/libs/axios'

import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BTableLite,
  BCardText,
  BButton,
  BAlert,
  BLink,
  VBToggle,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import { NumberToLetter } from 'convertir-nombre-lettre'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
  },
  data() {
    return {
      fields: [
        {
          key: 'customer',
          label: 'Client',
          formatter: customer => `${customer.company_name}`,
        },
        {
          key: 'customer.address',
          label: 'address',
        },
        {
          key: 'customer.phone',
          label: 'téléphone',
        },
        {
          key: 'customer.tax_identification_number',
          label: 'MF',
        },
      ],
      p: [],
      test: '0',
      order: [],
      lettre: '',
    }
  },

  created() {
    this.id = this.$route.params.id
    this.getOrder()
    this.getProducts()
  },
  methods: {
    format(date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },

    formatDate(date) {
      if (!date) {
        return null
      }
      const [year] = date.split('-')
      return `${year.slice(2, 4)}`
    },
    parseDate(date) {
      if (!date) {
        return null
      }
      const [month, day, year] = date.split('/')
      return `${year.padStart(0, '0')}-${month.padStart(2, '0')}-${day.padStart(
        2,
        '0',
      )}`
    },
    async getOrder() {
      const { data } = await axiosIns.get(`/orders/details/${this.id}/`)
      this.order = data
      const sommeString = this.order.price_ttc.toString()
      if (sommeString.includes('.000') === true) {
        this.lettre = `${NumberToLetter(this.order.price_ttc)} Dinars `
      } else {
        const [dinar, milim] = this.order.price_ttc.split('.')
        this.lettre = `${NumberToLetter(dinar)} Dinars , ${NumberToLetter(
          milim,
        )} `
      }
    },

    async getProducts() {
      const { data } = await axiosIns.post('/orders-products/details/', {
        order: this.id,
      })
      for (let i = 0; i < data.length; i += 1) {
        this.addnewitem(
          i + 1,
          data[i].reference,
          data[i].label,
          data[i].quantity,
          data[i].price_ht,
          data[i].total_ht,
          data[i].vat,
          data[i].price_ttc,
          data[i].total_ttc,
        )
      }
    },

    // eslint-disable-next-line camelcase
    addnewitem(
      nb,
      reference,
      label,
      quantity,
      price_ht,
      total_ht,
      tax,
      price_ttc,
      total_ttc,
    ) {
      this.p.push({
        Réf: reference,
        Désignation: label,
        Qté: quantity,
        'Prix U.H.T': price_ht,
        'Total H.T': total_ht,
        'Taux TVA': tax,
        'Prix U.TTC': price_ttc,
        'Total TTC': total_ttc,
        'N°': nb,
      })
    },
  },
  setup() {
    const invoiceData = ref(null)
    const paymentDetails = ref({})
    const sommeQte = 0
    const sommeCBM = 0
    const sommeGW = 0
    const sommeNW = 0

    const printInvoice = () => {
      window.print()
    }

    return {
      invoiceData,
      paymentDetails,
      printInvoice,
      sommeQte,
      sommeCBM,
      sommeGW,
      sommeNW,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.ta {
  white-space: nowrap;
  font-size: 18px;
  color: black;
}
th {
  padding: 10px;
  text-align: left;
}
.trborder {
  border-bottom: 1px solid black;
}

table {
  font-size: 18px;
  width: 100%;
}

.contact {
  background-color: #0b6746;
  padding: 10px;
  border-radius: 20px;
}
</style>

<style lang="scss">
@media print {
  .ta {
    white-space: nowrap;
    overflow-x: visible;
    font-size: 12px;
    color: black;
  }
  footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: auto; /* Footer height */
  }

  .table thead th {
    border-bottom: 2px solid black;
  }

  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }

  .contact {
    background-color: #0b6746;
    padding: 20px;
    border-radius: 25px;
    -webkit-print-color-adjust: exact;
  }
  footer footer-light footer-static {
    display: inline-block;
  }
  footer.footer {
    display: none;
  }

  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
  .tdt {
    padding: 10px;
    text-align: left;
    border: 1px solid black;
  }
  .tht {
    padding: 10px;
    text-align: left;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }
}
</style>
